import { format, getTime, formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// Import useTranslate for localization

import { Timezone } from '../types/timezone';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: Date | string, locale: string = 'en', newFormat?: string): string {
  if (!date) return '';

  const parsedDate = new Date(date);

  // Custom formatting if `newFormat` is provided.
  if (newFormat) {
    return format(parsedDate, newFormat);
  }

  // Default formatting using Intl.DateTimeFormat for locale.
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(parsedDate);
}

export const formatStr = {
  dateTime: 'DD MMM YYYY h:mm a', // 17 Apr 2022 12:00 am
  date: 'DD MMM YYYY', // 17 Apr 2022
  time: 'h:mm a', // 12:00 am
  split: {
    dateTime: 'DD/MM/YYYY h:mm a', // 17/04/2022 12:00 am
    date: 'DD/MM/YYYY', // 17/04/2022
  },
  paramCase: {
    dateTime: 'DD-MM-YYYY h:mm a', // 17-04-2022 12:00 am
    date: 'DD-MM-YYYY', // 17-04-2022
  },
};

export function fEventStartDate(
  startDate: string | Date,
  timezone: Timezone = Timezone['America/Montreal']
): string {
  const startInLocal = convertUtcToLocalDate(startDate, timezone);

  let fmt = 'dd LLL';
  const currentYear = new Date().getFullYear();
  const startYear = startInLocal.getFullYear();

  if (currentYear !== startYear) {
    fmt += ' yyyy';
  }

  const formattedStart = format(startInLocal, fmt);

  return formattedStart;
}

export function fEventDate(
  startDate: string | Date,
  endDate?: string | Date,
  timezone: Timezone = Timezone['America/Montreal'],
  locale: string = 'en' // Accept locale as a parameter, defaulting to 'en'
): string {
  const startInLocal = convertUtcToLocalDate(startDate, timezone);
  let endInLocal: Date | undefined;
  if (endDate) {
    endInLocal = convertUtcToLocalDate(endDate, timezone);
  }

  // Define date format options
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric', // We can conditionally remove the year if it's the same as current year
  };

  const currentYear = new Date().getFullYear();
  const startYear = startInLocal.getFullYear();
  const endYear = endInLocal ? endInLocal.getFullYear() : undefined;

  // Remove year from format if it's the current year
  if (currentYear === startYear) {
    delete options.year;
  }

  const formattedStart = new Intl.DateTimeFormat(locale, options).format(startInLocal);
  let formattedEnd = '';

  if (endInLocal) {
    const isSameDay =
      startInLocal.getDate() === endInLocal.getDate() &&
      startInLocal.getMonth() === endInLocal.getMonth() &&
      startInLocal.getFullYear() === endInLocal.getFullYear();

    if (isSameDay) {
      formattedEnd = new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' }).format(
        endInLocal
      );
      return `${formattedStart} - ${formattedEnd}`;
    }

    const endOptions: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      year: endYear && currentYear !== endYear ? 'numeric' : undefined,
    };
    formattedEnd = new Intl.DateTimeFormat(locale, endOptions).format(endInLocal);
    return `${formattedStart} - ${formattedEnd}`;
  }

  return formattedStart;
}

export function fDateTime(date: Date | string, locale: string = 'en', newFormat?: string): string {
  if (!date) return '';

  const parsedDate = new Date(date);

  // Custom formatting if `newFormat` is provided using date-fns or equivalent
  if (newFormat) {
    return format(parsedDate, newFormat);
  }

  // Default formatting using Intl.DateTimeFormat for localization
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(parsedDate);
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatDate(date: Date, tz: Timezone = Timezone['America/Montreal']): string {
  let fmt = 'dd LLL';
  const dateInUTC = convertUtcToLocalDate(date, tz);
  const currentYear = new Date().getFullYear();
  const dateYear = dateInUTC.getFullYear();

  if (currentYear !== dateYear) {
    fmt += ' yyyy';
  }

  return format(dateInUTC, fmt);
}

export function formatEventStartTime(date: Date, locale: string = 'en'): string {
  return new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
}

export function convertUtcToLocalDate(date: string | Date, organizerTimezone: Timezone): Date {
  const utcDate = new Date(date);
  const dateInOrganizerTz = utcToZonedTime(utcDate, organizerTimezone);
  return dateInOrganizerTz; // Return the local time, do not convert back to UTC.
}

export function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function calculateCartTimeLeft(createdAt?: Date) {
  if (!createdAt || createdAt === null) {
    // 15 minutes
    return 15 * 60;
  }
  const createdDate = new Date(createdAt).getTime();
  const currentDate = new Date().getTime();
  const fifteenMinutes = 15 * 60 * 1000;
  const timePassed = currentDate - createdDate;
  const timeLeft = fifteenMinutes - timePassed;

  // Convert to seconds and ensure it's not negative
  return Math.max(0, Math.floor(timeLeft / 1000));
}
